import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/video-streaming-developer-docs/video-streaming-developer-docs/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Check Q&A is enabled`}</h2>
    <pre><code parentName="pre" {...{}}>{`GET https://api.video.ibm.com/channels/{channelId}/settings/qna.json
`}</code></pre>
    <h3>{`Success response`}</h3>
    <p>{`Upon success a response with HTTP status “200 OK” is returned with the following key-value pairs.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`KEY`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`TYPE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`DESCRIPTION`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`enabled`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`boolean`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Q&A is enabled`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`is_default_interactive_module`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`boolean`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Q&A is the default interactive module on the channel page`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`Example of a success response:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "enabled": true,
  "is_default_interactive_module": false
}
`}</code></pre>
    <h3>{`Error responses`}</h3>
    <p>{`Possible error responses:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`HTTP RESPONSE CODE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`ERROR VALUE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`ERROR CONDITIONS`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`400 Bad Request`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`bad_request`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Invalid value was supplied`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`401 Unauthorized`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`invalid_token`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The provided access token is missing, revoked, expired or malformed`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`403 Unauthorized`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`lack_of_ownership`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The provided access token could not access the given channel`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`503 Service Unavailable`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`There is a temporary error on the server which makes it impossible to serve the request`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`Turn on/off Q&A`}</h2>
    <pre><code parentName="pre" {...{}}>{`PUT https://api.video.ibm.com/channels/{channelId}/settings/qna.json
`}</code></pre>
    <h3>{`Parameters`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`KEY`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`TYPE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`IMPORTANCE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`DESCRIPTION`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`enable`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`REQUIRED`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`true/false`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`The Content-Type of the request should be `}<strong parentName="p">{`application/x-www-form-urlencoded`}</strong>{`.`}</p>
    <p>{`Example of the request:`}</p>
    <pre><code parentName="pre" {...{}}>{`POST /channels/1234/settings/qna.json HTTP/1.1
Host: api.video.ibm.com
Authorization: Bearer 3c2573673b782f6544405a22636f3d5d3b6f3950
Content-Type: application/x-www-form-urlencoded

enable=true
`}</code></pre>
    <h3>{`Success response`}</h3>
    <p>{`Upon success the response body is empty with an HTTP Status code of 204.`}</p>
    <h3>{`Error responses`}</h3>
    <p>{`Possible error responses:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`HTTP RESPONSE CODE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`ERROR VALUE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`ERROR CONDITIONS`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`401 Unauthorized`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`invalid_token`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The provided access token is missing, revoked, expired or malformed`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`403 Unauthorized`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`lack_of_ownership`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The provided access token could not access the given channel`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`503 Service Unavailable`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`There is a temporary error on the server which makes it impossible to serve the request`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      